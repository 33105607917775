import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  @Input() userName: string = '';

  get initial(): string {
    return this.userName ? this.userName.charAt(0).toUpperCase() : '';
  }
}