import { Injectable } from '@angular/core';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { AccessAndRefreshToken } from 'src/app/models/access-refresh-token';
import { User } from 'src/app/models/user';

interface TokenPayload extends JwtPayload {
    attributes: User;
}

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    accessAndRefreshToken!: AccessAndRefreshToken;
    decodedToken!: TokenPayload;

    constructor() {
    }

    getAccessToken(): string {
        const accessAndRefreshToken = this.getTokensFromLocalStorage();
        if (accessAndRefreshToken != undefined) {
            return accessAndRefreshToken!.token || "";
        }
        return "";
    }

    getRefreshToken(): string {
        const accessAndRefreshToken = this.getTokensFromLocalStorage();
        if (accessAndRefreshToken != undefined) {
            return accessAndRefreshToken!.refresh_token || "";
        }
        return "";
    }

    setTokens(tokens: AccessAndRefreshToken) {
        localStorage.setItem("tokens", JSON.stringify(tokens));
    }

    getUser(): User {
        this.decodeAccessToken();
        return this.decodedToken ? this.decodedToken.attributes : {} as User;
    }

    isAccessTokenExpired(): boolean {
        this.decodeAccessToken();
        return this.isTokenExpired();
    }

    isRefreshTokenExpired(): boolean {
        this.decodeRefreshToken();
        return this.isTokenExpired();
    }

    clearToken() {
        localStorage.removeItem("tokens")
    }

    private decodeAccessToken() {
        this.decodeToken(this.getAccessToken())
    }

    private decodeRefreshToken() {
        this.decodeToken(this.getRefreshToken())
    }

    private decodeToken(token: string) {
        if (token) {
            const decodedToken = jwtDecode(token);

            if (decodedToken && typeof decodedToken !== 'string') {
                this.decodedToken = decodedToken as TokenPayload;
            } else {
                //TODO: handle null or string
                throw new Error('Invalid token format');
            }
        }
    }

    private isTokenExpired(): boolean {
        const expiryTime: number | null | undefined = this.getExpiryTime();
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
        } else {
            return true;
        }
    }

    private getExpiryTime() {
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    private getTokensFromLocalStorage(): AccessAndRefreshToken {
        const tokens = localStorage.getItem("tokens");
        if (tokens) {
            this.accessAndRefreshToken = JSON.parse(tokens);
        }

        return this.accessAndRefreshToken;
    }
}
