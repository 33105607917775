import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = "";
  password: string = "";

  constructor(private service: AuthService) {

  }

  onSubmit() {
    if (this.email && this.password) {
      this.service.login({ email: this.email, password: this.password })
      // Handle form submission
    }
  }

}
