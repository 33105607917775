import { Component } from '@angular/core';
import { User } from 'src/app/models/user';
import { TokenService } from 'src/app/services/token/token-service.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  [x: string]: any;
  appName: string = "SolarIA"
  user: User;

  constructor(private tokenService: TokenService) {
    this.user = this.tokenService.getUser();
  }

  get userJson(): string {
    return JSON.stringify(this.user)
  }

  public logout() {
    this.tokenService.clearToken();
    window.location.reload();
  }

}
