import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token-service.service';
import { AccessAndRefreshToken } from 'src/app/models/access-refresh-token';
import { tap } from 'rxjs';
import { Paths } from 'src/app/utils/paths';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = environment.backendUrl + "/auth"

  constructor(private http: HttpClient,
    private tokenService: TokenService,
    private router: Router,
    private snackBar: MatSnackBar) {
  }

  login(credentials: { email: string; password: string; }) {
    this.http.post<any>(this.baseUrl, credentials).subscribe({
      next: (data) => {
        this.tokenService.setTokens(data);
        this.router.navigate([Paths.HOME])
      },
      error: (error) => {
        this.snackBar.open("Email e/ou Password inválidos", "Fechar", {
          duration: 4000,
        })
      }
    });
  }

  refreshToken() {
    const refreshToken = this.tokenService.getRefreshToken();
    this.tokenService.clearToken();
    return this.http.post<AccessAndRefreshToken>(environment.backendUrl + "/refresh_token", { "refresh_token": refreshToken })
      .pipe(tap({
        next: (response) => {
          this.tokenService.setTokens(response);
        }
      }));
  }

}
