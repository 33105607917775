import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, throwError } from 'rxjs';
import { EstimateParams } from 'src/app/components/view/home/home.component';
import { LoadSchedule } from 'src/app/models/load-schedule';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoadScheduleService {
  private baseUrl: string = environment.backendUrl + "/loads-schedule"

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  save(loadSchedule: LoadSchedule) {
    const { id, updated_at, created_at, ...data } = loadSchedule;
    data.electrical_loads = [];
    return this.http.post<{ id: number }>(this.baseUrl, data).pipe(catchError(error => {
      this.snackBar.open(error.error.error, "Close", {
        duration: 4000,
      })
      return throwError(error)
    }));
  }

  update(loadSchedule: LoadSchedule) {
    const { id, electrical_loads, updated_at, created_at, ...data } = loadSchedule;
    let t: any = []
    const teste = { ...data, "electrical_loads": t };
    loadSchedule.electrical_loads.forEach(element => {
      teste.electrical_loads.push(element);
    });
    return this.http.put<LoadSchedule>(`${this.baseUrl}/${loadSchedule.id}`, teste);
  }

  getEstimation(id: number, estimateParams: EstimateParams) {
    return this.http.get<any>(`${this.baseUrl}/${id}/estimate?desired_autonomy=${estimateParams.autonomy || 1}&battery_capacity=${estimateParams.batteryCapacity}&bank_voltage=${estimateParams.bankVoltage}&battery_type=${estimateParams.batteryType}`);
  }

  getAllSelect(estimateType?: string) {
    const url = estimateType ? `${this.baseUrl}?estimate_type=${estimateType}` : this.baseUrl;
    return this.http.get<any>(url);
  }

  getLoadScheduleList(id: any) {
    return this.http.get<LoadSchedule>(`${this.baseUrl}/${id}`);
  }

}
