<ng-container>
   <div class="toggle-group">
      <mat-button-toggle-group [(ngModel)]="estimateTypeSelected" *ngFor="let estimateType of estimateTypes"
         (click)="systemTypeToggle()">
         <mat-button-toggle [value]="estimateType">{{estimateType}}</mat-button-toggle>
      </mat-button-toggle-group>
   </div>
   <mat-card-content class="mat-card-content-master">
      <div id="form-general">
         <div id="form-section">
            <div id="form-load-schedule">
               <mat-form-field class="example-full-width">
                  <mat-label>Nome do Projeto:</mat-label>
                  <input matInput type="text" [(ngModel)]="loadSchedule.project_name" required="true">
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Quantidade de Fases:</mat-label>
                  <mat-select [(ngModel)]="loadSchedule.phase_quantity" required="true">
                     <mat-option [value]="1">1</mat-option>
                     <mat-option [value]="2">2</mat-option>
                     <mat-option [value]="3">3</mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Nível de Tensão:</mat-label>
                  <mat-select [(ngModel)]="loadSchedule.voltage_level" required="true">
                     <mat-option value="127/220">127/220V</mat-option>
                     <mat-option value="220/380">220/380V</mat-option>
                     <mat-option value="220/440">220/440V</mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Tensão CC do Sistema:</mat-label>
                  <mat-select [(ngModel)]="loadSchedule.bank_voltage" (valueChange)="calculateBatteryCapacity()"
                     required="true">
                     <mat-option [value]="12">12V</mat-option>
                     <mat-option [value]="24">24V</mat-option>
                     <mat-option [value]="48">48V</mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Tipo da Bateria:</mat-label>
                  <mat-select [(ngModel)]="loadSchedule.battery_type" required="true">
                     <mat-option *ngFor="let batteryType of batteryTypes" [value]="batteryType">
                        {{batteryType | batteryType}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-grid-list cols="3" rowHeight="2:1" fxLayout.lg="column">
                  <mat-grid-tile colspan="2">
                     <mat-form-field>
                        <mat-label>Capacidade da Bateria:</mat-label>
                        <input matInput type="number" [(ngModel)]="batteryCapacity" (blur)="calculateBatteryCapacity()">
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                     <mat-form-field>
                        <mat-label>Unidade:</mat-label>
                        <mat-select [(ngModel)]="batteryCapacityUnit" (closed)="calculateBatteryCapacity()">
                           <mat-option value="Ah">Ah</mat-option>
                           <mat-option value="kWh">kWh</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </mat-grid-tile>
               </mat-grid-list>
               <button mat-raised-button color="accent" (click)="onSubmit()"
                  [ngClass]="{'disabled': loadSchedule.id}">Cadastrar Projeto</button>
            </div>
         </div>

         <div class="load-inputs" [ngClass]="{'disabled': !isLoadScheduleSelected}">
            <h3>Adicionar Carga</h3>
            <mat-form-field>
               <mat-label>Selecione o Projeto:</mat-label>
               <mat-select [(value)]="selectedLoadSchedule" (selectionChange)="fetchLoadSchedule($event)">
                  <!-- <mat-option value=""></mat-option> -->
                  <mat-option *ngFor="let loadSchedule of loadScheduleList" [value]="loadSchedule.id">
                     {{loadSchedule.project_name}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <div class="load-inputs" [ngClass]="{'disabled': !selectedLoadSchedule}">
               <mat-form-field>
                  <mat-label>Nome:</mat-label>
                  <input matInput type="text" [(ngModel)]="loadName">
               </mat-form-field>

               <mat-form-field>
                  <mat-label>Tipo da Carga:</mat-label>
                  <mat-select [(ngModel)]="loadType">
                     <mat-option *ngFor="let loadType of loadTypes" [value]="loadType">
                        {{loadType | loadType}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <!-- <div class="side-by-side"> -->
               <mat-grid-list cols="4" rowHeight="4:3">
                  <mat-grid-tile colspan="3">
                     <mat-form-field *ngIf="!isConstant()">
                        <mat-label>Potência (W):</mat-label>
                        <input matInput type="number" [(ngModel)]="loadElectricalPower">
                     </mat-form-field>
                     <mat-form-field *ngIf="isConstant()">
                        <mat-label>Consumo (kWh/mês):</mat-label>
                        <input matInput [matTooltip]="loadTooltip" matTooltipPosition="above" type="number"
                           [(ngModel)]="loadElectricalPower">
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                     <mat-form-field>
                        <mat-label>Quantidade:</mat-label>
                        <input matInput type="number" [(ngModel)]="loadQuantity">
                     </mat-form-field>
                  </mat-grid-tile>
               </mat-grid-list>
               <!-- </div> -->
               <div class="hours-container" *ngIf="isOffgrid()">
                  <mat-label>Horas de Uso:</mat-label>
                  <label class="checkbox-text" style="width: auto; margin-right: 5px; font-weight: 500;"
                     (click)="selectAllHours()">Todas</label>
                  <ng-container *ngFor="let hour of HOURS_ARRAY">
                     <label class="checkbox-text" [ngClass]="{
                         'checked-background': hour.selected,
                         'unchecked-background':!hour.selected
                       }">
                        <input type="checkbox" [(ngModel)]="hour.selected"
                           style="display: none;">{{hour.value}}h</label>
                  </ng-container>
               </div>
               <button mat-raised-button color="accent" (click)="addLoad()">Add carga ➡️</button>
            </div>
         </div>
      </div>
      <div class="table-canvas">
         <div *ngIf="isOffgrid()">
            <canvas id="canvas">{{chart}}</canvas>
         </div>

         <div id="load-table">
            <mat-table [dataSource]="loadListDataSource">
               <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef> Tipo de Carga </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.type_load | loadType}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="electricalPower">
                  <mat-header-cell *matHeaderCellDef> Potência (W) </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.electrical_power | number : '1.1-2'}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="quantity">
                  <mat-header-cell *matHeaderCellDef> Qtd. </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.quantity }} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="totalElectricalPower">
                  <mat-header-cell *matHeaderCellDef> Total Potência (W) </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.electrical_power * element.quantity | number :
                     '1.1-2'}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="totalHoursOfUsage">
                  <mat-header-cell *matHeaderCellDef> Total de Horas de uso </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{getHours(element.load_curve)}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="usageHours">
                  <mat-header-cell *matHeaderCellDef> Horas de Uso </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{getHours(element.load_curve) == 24 ? "0-23" :
                     element.load_curve}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="delete">
                  <mat-header-cell *matHeaderCellDef> Deletar </mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">
                     <button mat-icon-button (click)="removeLoad(i)">
                        <mat-icon>delete</mat-icon>
                     </button>
                  </mat-cell>
               </ng-container>
               <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
               <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
               <mat-row *matNoDataRow colspan="4">No data</mat-row>
            </mat-table>
         </div>
         <div id="div-button-estimation" [ngClass]="{'disabled2': !selectedLoadSchedule}">
            <ng-container>
               <mat-grid-list [cols]="gridListColumns" gutterSize="1px" [rowHeight]="gridListRowHeight" (window:resize)="handleResize($event)">
                  <mat-grid-tile [colspan]="estimationInputCols">
                     <mat-form-field>
                        <mat-label>Autonomia:</mat-label>
                        <input matInput type="number" [(ngModel)]="estimateParams.autonomy">
                        <span matSuffix>{{autonomyUnit}}</span>
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="estimationInputCols" rowspan="1">
                     <mat-form-field>
                        <mat-label>Tensão CC:</mat-label>
                        <mat-select [(ngModel)]="estimateParams.bankVoltage">
                           <mat-option [value]="12">12V</mat-option>
                           <mat-option [value]="24">24V</mat-option>
                           <mat-option [value]="48">48V</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="estimationInputCols" rowspan="1">
                     <mat-form-field>
                        <mat-label>Tipo da Bateria:</mat-label>
                        <mat-select [(ngModel)]="estimateParams.batteryType">
                           <mat-option *ngFor="let batteryType of batteryTypes" [value]="batteryType">
                              {{batteryType | batteryType}}
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="estimationInputCols" rowspan="1">
                     <mat-form-field>
                        <mat-label>Capacidade da Bateria:</mat-label>
                        <input id="input-estimation" matInput type="number" [(ngModel)]="batteryCapacityEstimation" (blur)="calculateBatteryCapacityEstimation()">
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="estimationInputCols" rowspan="1">
                     <mat-form-field>
                        <mat-label>Unidade:</mat-label>
                        <mat-select [(ngModel)]="batteryCapacityUnitEstimation" (closed)="calculateBatteryCapacityEstimation()">
                           <mat-option value="Ah">Ah</mat-option>
                           <mat-option value="kWh">kWh</mat-option>
                        </mat-select>
                     </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="estimationButtonCols" rowspan="1">
                     <button class="button-estimation" (click)="callEstimation()">Calcular Sistema Off Grid</button>
                  </mat-grid-tile>
               </mat-grid-list>
            </ng-container>
         </div>
      </div>
   </mat-card-content>
</ng-container>