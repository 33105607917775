import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BatteryTypePipe } from 'src/app/pipes/battery-type-pipe';
/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'app-dialog-estimation',
  templateUrl: './dialog-estimation.component.html',
  styleUrls: ['./dialog-estimation.component.scss'],
  standalone: true,
  imports: [MatDialogModule, NgIf]
})
export class DialogEstimationComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
   }

}
