<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="menu-icon">
      <mat-icon>menu</mat-icon>
    </span>
    <div class="logo">
      <img src="/assets/logo_solaria.png" alt="SolarIA Logo">
      <span class="app-name">{{appName}}</span>
    </div>
    <button mat-icon-button aria-label="Example icon-button with menu icon" [matTooltip]="userJson" [matMenuTriggerFor]="menu">
      <app-user-avatar [userName]="user.name"></app-user-avatar>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">Sair</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
