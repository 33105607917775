// src/app/components/view/home/home.component.ts
import { AfterViewInit, Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Chart } from 'chart.js/auto';
import { Load } from 'src/app/models/load';
import { LoadSchedule } from 'src/app/models/load-schedule';
import { EstimateType } from 'src/app/models/enums/estimate-type';
import { LoadScheduleService } from 'src/app/services/load-schedule/load-schedule.service';
import { DialogEstimationComponent } from './dialog-estimation/dialog-estimation.component';
import { LoadTypePipe } from 'src/app/pipes/load-type-pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BatteryType } from 'src/app/models/enums/battery-type';
import { EnumUtils } from 'src/app/utils/enum-utils';
import { LoadType } from 'src/app/models/enums/load-type';

export interface HourSelect {
  value: number;
  selected: boolean;
}

export interface EstimateParams {
  autonomy: number;
  bankVoltage: number;
  batteryType: BatteryType;
  batteryCapacity: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [LoadTypePipe]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  /* loadScheduleForm!: FormGroup; */
  loadName = 'loadName';
  loadType = 'default';
  loadElectricalPower = 400;
  loadUsageHours = 0;
  loadQuantity = 1;
  loadList: Load[] = [];
  loadListDataSource: MatTableDataSource<Load> = new MatTableDataSource<Load>();
  displayedColumns: string[] = ['name', 'type', 'quantity', 'electricalPower', 'totalElectricalPower', 'totalHoursOfUsage', 'usageHours', 'delete'];
  HOURS_ARRAY: HourSelect[] = Array.from({ length: 24 }, (_, i): HourSelect => { return { value: i, selected: false } });
  chart!: Chart;
  chartData: { [hour: number]: number } = {};
  loadSchedule: LoadSchedule = {} as LoadSchedule;
  loadScheduleList: LoadSchedule[] = [];
  selectedLoadSchedule!: LoadSchedule;
  isLoadScheduleSelected: boolean = true;
  isLoadSaved: boolean = false;
  estimation: any;
  autonomy: number = 1;
  autonomyUnit: string = "dias";
  estimateTypeSelected: EstimateType = EstimateType.OFFGRID;
  estimateTypes: EstimateType[] = EnumUtils.getEnumValues(EstimateType);
  batteryTypes: BatteryType[] = EnumUtils.getEnumValues(BatteryType);
  loadTypes: LoadType[] = EnumUtils.getEnumValues(LoadType);
  batteryCapacity: number = 0;
  batteryCapacityEstimation: number = 0;
  batteryCapacityUnit: string = "kWh";
  loadTooltip: string = "Por favor preencha esse campo com o CONSUMO (kWh/mês) do equipamento, que pode ser encontrado neste link https://pbe.inmetro.gov.br/#/programas";
  estimateParams: EstimateParams = {} as EstimateParams;
  batteryCapacityUnitEstimation: string = "kWh";
  estimationButtonCols: number = 10;
  estimationInputCols: number = 2;
  gridListColumns: number = 10;
  gridListRowHeight: string = "1:1";

  constructor(private fb: FormBuilder, private service: LoadScheduleService, public dialog: MatDialog) {
    this.loadSchedule.bank_voltage = 12;
  }

  ngOnInit(): void {
    /*     this.initializeChart(); */
    this.estimateParams.autonomy = 1;
  }

  ngAfterViewInit(): void {
    this.initializeChart();
    this.fillLoadScheduleSelect();
  }

  ngOnDestroy(): void {
    this.clearChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.checkWindowSize(window.innerWidth);
  }

  private initializeChart() {
    this.clearChartData();
    this.chart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: Object.keys(this.chartData).map(Number),
        datasets: [
          {
            label: 'Potência Total por Hora',
            data: Object.values(this.chartData).map(Number),
            borderWidth: 1,
            backgroundColor: '#00bf63',
            borderColor: '#032e50',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    this.chart.update();
  }

  private fillLoadScheduleSelect() {
    this.service.getAllSelect(this.estimateTypeSelected).subscribe({
      next: (res) => {
        this.loadScheduleList = res.attributes;
      },
      error: (error) => {
        console.error('Error trying to load the Load Schedules:', error);
      }
    });;
  }

  private clearChartData() {
    for (let i = 0; i < 24; i++) {
      this.chartData[i] = 0;
    }
  }

  public async addLoad(): Promise<void> {
    let selectedHours = Array.from(this.HOURS_ARRAY.filter(h => h.selected).map(h => h.value).values());
    const load: Load = {
      name: this.loadName,
      type_load: this.loadType,
      electrical_power: LoadType.CONSTANT === this.loadType ? this.calculateLoadElectricalPower(this.loadElectricalPower) : this.loadElectricalPower,
      load_curve: selectedHours,
      quantity: this.loadQuantity,
      usage_hours: this.getHours(selectedHours),
      loads_schedule_id: this.selectedLoadSchedule.id
    };
    await this.saveLoad(load);
    await this.addLoadToChart(load);
  }

  private async addLoadToChart(load: Load) {

    load.load_curve.forEach(h => {
      this.chartData[h] += load.electrical_power;
    });

    this.isLoadSaved = true;
    this.loadListDataSource.data = this.loadList;
    /*     this.loadScheduleForm.patchValue({ electricalLoadList: this.loadList }) */
    // Reset the input fields
    this.updateChart();
    this.clearFields();
  }

  private clearFields() {
    this.HOURS_ARRAY.forEach(h => h.selected = false);
    this.loadName = '';
    this.loadType = '';
    this.loadElectricalPower = 0;
    this.loadUsageHours = 0;
  }

  private updateChart() {
    this.chart.data.labels = [];
    this.chart.data.datasets[0].data = [];
    this.clearChartData();

    this.loadList.forEach(load => {
      load.load_curve.forEach(hour => {
        this.chartData[hour] += load.electrical_power;
      });
    });
    this.chart.data.labels = Object.keys(this.chartData).map(Number)
    this.chart.data.datasets[0].data = Object.values(this.chartData).map(Number);

    this.chart.update();
  }

  onSubmit(): void {
    this.loadSchedule.phase_quantity = Number.parseInt(this.loadSchedule.phase_quantity.toString());
    this.loadSchedule.bank_voltage = Number.parseInt(this.loadSchedule.bank_voltage.toString());
    // this.loadSchedule.battery_type = getBatteryTypeEnum(this.loadSchedule.battery_type);
    this.loadSchedule.estimate_type = this.estimateTypeSelected;
    debugger;
    this.service.save(this.loadSchedule).subscribe({
      next: (res) => {
        this.loadSchedule.id = res.id
        this.fillLoadScheduleSelect();

      },
      error: (error) => {
        console.error('Error trying to save the Load Schedule:', error);
      }
    });
  }

  private async saveLoad(load: Load) {
    this.loadList.push(load);
    this.loadSchedule.electrical_loads = this.loadList;
    this.service.update(this.loadSchedule).subscribe({
      next: (res) => {
        this.loadSchedule = res
      },
      error: (error) => {
        console.error('Error trying to save the Load Schedule:', error);
      }
    });
  }

  private async deleteLoad(load: Load) {
    this.loadList = this.loadList.filter(l => l.id !== load.id);
    this.loadSchedule.electrical_loads = this.loadList;
    this.service.update(this.loadSchedule).subscribe({
      next: (res) => {
        this.loadSchedule = res
      },
      error: (error) => {
        console.error('Error trying to save the Load Schedule:', error);
      }
    });

  }

  getHours(hourSelectList: number[]): number {
    return hourSelectList.length;
  }

  async removeLoad(index: number) {
    const load = this.loadListDataSource.data[index];
    await this.deleteLoad(load);

    load.load_curve.forEach(h => {
      this.chartData[h] -= load.electrical_power;
    });
    this.loadListDataSource.data.splice(index, 1);
    this.loadListDataSource = new MatTableDataSource(this.loadListDataSource.data);
    this.updateChart();
  }

  public callEstimation() {
    this.service.getEstimation(this.loadSchedule.id, this.estimateParams).subscribe({
      next: (res) => {
        // this.estimation = this.formatEstimation(res);
        // this.estimation = res;
        this.dialog.open(DialogEstimationComponent, {
          data: { estimation: res },
          panelClass: 'custom-dialog'
        });
      },
      error: (error) => {
        console.error('Error trying to get the estimation:', error);
      }
    });
  }

  public fetchLoadSchedule(event: any) {
    const selectedId = event.value;
    this.loadList = [];
    this.estimation = null;
    if (selectedId) {
      //Call Service to fetch all the data for this load schedule
      this.service.getLoadScheduleList(selectedId).subscribe({
        next: (res) => {
          this.clearChartData();
          res.phase_quantity = Number.parseInt(res.phase_quantity.toString());
          this.loadSchedule = res;
          this.batteryCapacity = this.batteryCapacityUnit === "Ah" ? this.loadSchedule.bank_voltage / this.loadSchedule.battery_capacity : this.loadSchedule.battery_capacity;
          this.loadSchedule.electrical_loads.forEach((el: Load) => {
            this.addLoadToChart(el)
          });
          this.loadList = this.loadSchedule.electrical_loads;
          this.fillEstimateParams(this.loadSchedule);
          this.updateChart();
          this.loadListDataSource.data = this.loadList;
        },
        error: (error) => {
          console.error('Error trying to get the estimation:', error);
        }
      });
    } else {
      this.loadSchedule = {} as LoadSchedule;
      this.updateChart();
      this.loadListDataSource.data = this.loadList;
    }
  }

  public systemTypeToggle() {
    if (this.isOffgrid()) {
      setTimeout(() => this.initializeChart(), 0);
      this.autonomyUnit = "dias";
    } else {
      this.autonomyUnit = "horas";
    }
    this.fillLoadScheduleSelect();
    this.loadSchedule = {} as LoadSchedule;
    this.batteryCapacity = 0;
    this.loadListDataSource.data = [];
    this.selectedLoadSchedule = this.loadSchedule;
  }

  isOffgrid(): boolean {
    return this.estimateTypeSelected === EstimateType.OFFGRID;
  }

  calculateBatteryCapacity() {
    if (this.batteryCapacityUnit === "Ah") {
      this.loadSchedule.battery_capacity = this.batteryCapacity * this.loadSchedule.bank_voltage;
      return;
    }
    this.loadSchedule.battery_capacity = this.batteryCapacity;
  }

  calculateBatteryCapacityEstimation() {
    if (this.batteryCapacityUnitEstimation === "Ah") {
      this.estimateParams.batteryCapacity = this.batteryCapacityEstimation * this.estimateParams.bankVoltage;
      return;
    }
    this.estimateParams.batteryCapacity = this.batteryCapacityEstimation;
  }

  selectAllHours() {
    const hasAnyNonSelected: boolean = this.HOURS_ARRAY.filter(e => e.selected == false).length > 0;
    this.HOURS_ARRAY.forEach(element => {
      element.selected = hasAnyNonSelected;
    });
  }

  isConstant(): boolean {
    return LoadType.CONSTANT === this.loadType;
  }

  calculateLoadElectricalPower(loadElectricalPower: number): number {
    return ((loadElectricalPower / 30) / 24) * 1000;
  }

  private fillEstimateParams(loadSchedule: LoadSchedule) {
    this.estimateParams.autonomy = 1;
    this.estimateParams.bankVoltage = loadSchedule.bank_voltage;
    this.batteryCapacityEstimation = this.batteryCapacityUnitEstimation === "Ah" ? loadSchedule.battery_capacity * this.estimateParams.bankVoltage : loadSchedule.battery_capacity;
    this.estimateParams.batteryCapacity = this.batteryCapacityEstimation;
    this.estimateParams.batteryType = loadSchedule.battery_type;
  }

  handleResize(event: UIEvent) {
    const window = event.target as Window;
    this.checkWindowSize(window.innerWidth);
  }

  private checkWindowSize(width: number){
    if (width <= 600) {
      this.estimationButtonCols = 1;
      this.estimationInputCols = 1;
      this.gridListColumns = 1;
      this.gridListRowHeight = "6:1";
    } else {
      this.estimationButtonCols = 10;
      this.estimationInputCols = 2;
      this.gridListColumns = 10;
      this.gridListRowHeight = "1:1";
    }
  }
}

