import { Pipe, PipeTransform } from "@angular/core";
import { BatteryType } from "../models/enums/battery-type";

@Pipe({ name: "batteryType" })
export class BatteryTypePipe implements PipeTransform {
    transform(value: BatteryType) {
        switch (value) {
            case BatteryType.LEAD_ACID: return "Chumbo Ácido";
            case BatteryType.LITHIUM: return "Lítio";
            case BatteryType.AGM: return "AGM";
            case BatteryType.GEL: return "Gel";
        }
    }
}