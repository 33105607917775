import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TokenService } from '../token/token-service.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class UniversalAppInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router,
    private tokenService: TokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = (req.url.search("refresh") || req.url.search("authenticate") || req.url.search("validate")) == -1 ?
      { Authorization: `Bearer ${this.tokenService.getAccessToken()}` } :
      { Authorization: "" };

    req = req.clone({
      url: req.url,
      setHeaders: headers
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.includes('refresh_token')) {
          // If the request failed due to unauthorized access, attempt to refresh the token
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              // Retry the original request with the new access token
              const accessToken = this.tokenService.getAccessToken();
              const authRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${accessToken}`
                }
              });
              return next.handle(authRequest);
            }),
            catchError((refreshError: any) => {
              this.tokenService.clearToken();
              window.location.reload();
              //TODO: See how to handle the error return
              return throwError(refreshError);
            })
          );
        } else if (error.status == 403) {
          // this.tokenService.clearToken();
          // window.location.reload();
          return next.handle(req);
        }
        //TODO: See how to handle the error return
        return throwError(error);
      })
    );
  }
}