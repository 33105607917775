<h2 mat-dialog-title>Estimativa</h2>
<mat-dialog-content class="mat-typography">

   <h2>Banco de Baterias</h2>
   <p>Autonomia (dias): {{data.estimation.battery_bank.autonomy_days}}</p>
   <p>Tipo de Bateria: {{data.estimation.battery_bank.battery_type}}</p>
   <p>Voltagem da Bateria: {{data.estimation.battery_bank.bank_voltage}}V</p>
   <p>Capacidade: {{data.estimation.battery_bank.capacity}}kWh</p>
   <p>Número de Baterias: {{data.estimation.battery_bank.number_of_batteries}}</p>

   <h2>Inversor</h2>
   <p>Eficiência: {{(data.estimation.inverter.efficiency * 100).toFixed(2)}}%</p>
   <p>Potência Máxima: {{data.estimation.inverter.max_power}}kW</p>
   <p>Potência Nominal: {{data.estimation.inverter.rated_power}}kW</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
</mat-dialog-actions>