import { Pipe, PipeTransform } from "@angular/core";
import { LoadType } from "../models/enums/load-type";

@Pipe({ name: "loadType" })
export class LoadTypePipe implements PipeTransform {
    transform(value: LoadType) {
        switch (value) {
            case LoadType.MOTOR: return "Motor";
            case LoadType.DEFAULT: return "Padrão";
            case LoadType.CONSTANT: return "Refrigerador, Aquecedor ou Ar-condicionado";
        }
    }
}