<!-- src/app/components/view/login/login.component.html -->

    <div id="container">
    <div class="left-panel">
      <img src="/assets/wp_login.jpg" alt="Background Image">
    </div>

    <div class="right-panel">
      <div class="logo">
        <img src="/assets/logo_solaria.png" alt="SolarIA Logo">
        <h1>SolarIA</h1>
        <p>A SUA PLATAFORMA DE ENERGIA</p>
      </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="email" name="email" required email>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput [(ngModel)]="password" name="password" required type="password">
        </mat-form-field>
        <button mat-button matRipple mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Login</button>
      </form>
      <div class="social-icons">
        <a href="#"><img src="/assets/instagram.png" alt="Instagram"></a>
        <a href="#"><img src="/assets/facebook.png" alt="Facebook"></a>
        <a href="#"><img src="/assets/twitter.png" alt="Twitter"></a>
      </div>
    </div>
  </div>
