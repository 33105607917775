import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Paths } from 'src/app/utils/paths';
import { TokenService } from '../token/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard {
  constructor(
    private tokenService: TokenService,
    private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    /* if (state.url.startsWith("/" + Paths.ACCOUNT_CONFIRMATION) && next.queryParams['token']) {
      return true;
    } */
    if (this.tokenService.getUser().email) {
      if (this.tokenService.isAccessTokenExpired()) {
        if (this.tokenService.isRefreshTokenExpired()) {
          this.router.navigate([Paths.LOGIN])
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      this.router.navigate([Paths.LOGIN])
      return false;
    }
  }
}