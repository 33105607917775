import { Component } from '@angular/core';
import { TokenService } from './services/token/token-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ext-solaria-front';

  constructor(private tokenService: TokenService) { }

  get logged(): boolean {
    return !this.tokenService.isAccessTokenExpired() && window.location.pathname.indexOf("account-confirmation") == -1
  }
}
